import React, { Fragment } from 'react'
import { serialize } from 'dom-form-serializer'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import './Form.css'

class NewsletterForm extends React.Component {
  static defaultProps = {
    name: 'ContactForm',
    subject: '', // optional subject of the notification email
    action: '',
    successMessage: 'Obrigado! Vamos lhe responder assim que possível.',
    errorMessage:
      'Hmmm... Algo inesperado aconteceu. :('
  }

  state = {
    alert: '',
    disabled: false
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const data = serialize(form)
    this.setState({ disabled: true })

    addToMailchimp(data.emailAddress, {
      NAME: data.name,
      MESSAGE: data.message
    })
      .then(res => {
        if (res.result == 'success' || res.msg.includes('already subscribed')) {
          if (typeof window.fbq === 'function') {
            window.fbq('track', 'Lead');
          };
          
          return res
        } else {
          console.log(res)
          throw new Error('Network error')
        }
      })
      .then(() => {
        this.setState({
          alert: this.props.successMessage,
          disabled: false,
          sucess: 'true'
        })
        form.reset()
      })
      .catch(err => {
        console.error(err)
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    const { name, subject, action } = this.props

    return (
      <Fragment>
        <form
          className="Form"
          name={name}
          action={action}
          onSubmit={this.handleSubmit}
          data-netlify=""
          netlify-recaptcha=""
        >

          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="text"
              placeholder="Nome"
              name="name"
              required
            />
            <span>Nome</span>
          </label>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="email"
              placeholder="Email"
              name="emailAddress"
              required
            />
            <span>Email</span>
          </label>
          <label className="Form--Label">
            <textarea
              className="Form--Input Form--Textarea Form--InputText"
              placeholder="Message"
              name="message"
              rows="10"
              required
            />
            <span>Mensagem</span>
          </label>
          <div
            className="g-recaptcha"
            data-sitekey="6LfKN3kUAAAAAGIM1CbXmaRZx3LIh_W2twn1tzkA"
          />
          {!!subject && <input type="hidden" name="subject" value={subject} />}
          <input
            className="Button Form--SubmitButton"
            type="submit"
            value="Enviar"
            disabled={this.state.disabled}
          />
          {this.state.alert && (
            <div className="Form--Alert">{this.state.alert}</div>
          )}
        </form>
      </Fragment>
    )
  }
}

export default NewsletterForm
